.App {
    display: flex;
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    display: flex;
}

.App-body {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #3B3836;
    color: #BAB1AB
}

.Content-photo {
    width: 50%;
}

.Container {
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    width: auto;
    height: auto;
}

.Main-Image {
    height: 50%;
    width: 50%;
    padding: 5%;
}

.Navigation-panel {
    display:flex;
    top: 0;
    background-color: rgb(255, 255, 255);
    width: 100vw;
    padding: 1rem;
}

ul li a {
    color: #2645dc;
}

ul li a:hover {
    color: #7f95ff;
}

ul li a.active {
    color: rgb(9, 0, 186);
    font-weight: bolder;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}